const routes = [{
        path: '/',
        component: () => import('@/view/index')
    },
    {
        path: '/index',
        component: () => import('@/view/index')
    },
    {
        path: '/map',
        component: () => import('@/view/map')
    }

];

export default routes