<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
* {
  margin: 0;
  padding: 0;
}
html,
body,
#app {
  width: 100%;
  height: 100%;
}
.flex {
  display: flex;
}
.center {
  align-items: center;
}
.between {
  /* 2边对齐 */
  justify-content: space-between;
}
.absolute {
  position: absolute;
}
.pointer {
  cursor: pointer;
}
.amap-logo,
.amap-copyright {
  display: none !important;
}
.amap-marker-label {
  width: 60px;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  height: 18px;
  font-family: STLiti;
  color: green;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.4);
  border: solid 2px rgba(100, 255, 200, 0.8);
}
.my_marker {
  width: fit-content;
  display: flex;
}
.my_marker img {
  margin-right: 5px;
  width: 30px;
  height: 30px;
}
.marker {
  /* width: fit-content; */
  display: block;
  min-width: 200px;
  background: #f1ac20;
  color: white;
  text-align: center;
  font-size: 12px;
  white-space: initial;
  word-break: normal;
  height: fit-content;
  padding: 5px;
}
.list_info img {
  max-width: 90%;
}
.swiper-pagination-bullet-active {
  background: #f1ac20 !important;
}
.swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
}
.con2-content span {
  background: none !important;
  color: white;
}
</style>
