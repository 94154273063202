import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router/index'
import * as echarts from "echarts";
import "swiper/css/swiper.min.css"

// 引入element
const vueMain = createApp(App).use(router).mount('#app')

vueMain.echarts=echarts

export default vueMain